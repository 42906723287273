import React from 'react'
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

const Loading = ({ t }) => (
  <div className="text-center my-3">
    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
    {t("Loading")}
  </div>
)

Loading.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(Loading))
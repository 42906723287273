import { post, get, del, patch } from "./api_helper"
import * as url from "./url_helper"

const postJwtLogin = (data) => post({ url: url.POST_JWT_LOGIN, data })

const getListAllOrganizations = async () => await get({ url: url.ORGANIZATIONS })
const createOrganization = async (item) => await post({ ...item, url: url.ORGANIZATIONS })
const deleteOrganization = async (item) => await del({ ...item, url: url.ORGANIZATIONS })
const updateOrganization = async (item) => await patch({ ...item, url: url.ORGANIZATIONS })

const getListAllChannels = async (item) => await get({ ...item, url: url.CHANNELS })
const createChannel = async (item) => await post({ ...item, url: url.CHANNELS })
const deleteChannel = async (item) => await del({ ...item, url: url.CHANNELS })
const updateChannel = async (item) => await patch({ ...item, url: url.CHANNELS })

const getListAllAccounts = async (item) => await get({ ...item, url: url.GET_ALL_ACCOUNTS })

export {
  postJwtLogin,
  getListAllOrganizations,
  createOrganization,
  deleteOrganization,
  updateOrganization,
  getListAllChannels,
  createChannel,
  deleteChannel,
  updateChannel,
  getListAllAccounts
}

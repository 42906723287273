import React from 'react'
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'

const HeaderTitle = ({ text }) => (
  <MetaTags>
    <title>{text} | FH SuperPortal</title>
  </MetaTags>
)

HeaderTitle.propTypes = {
  text: PropTypes.string
}

export default HeaderTitle
import PropTypes from "prop-types"
import React, { useEffect, createRef } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { useAdmin } from 'context/AdminContext'

const LinkGenerator = ({ link, icon, text }) => (
  <li>
    <Link to={link}>
      <i className={icon} />
      <span>{text}</span>
    </Link>
  </li>
)

LinkGenerator.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string
}


const SidebarContent = ({ location, t }) => {
  const { adminData } = useAdmin()
  const refDiv = createRef()

  useEffect(() => {
    initMenu()
  }, [])

  const initMenu = () => {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }

  const scrollElement = item => {
    setTimeout(() => {
      if (refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (refDiv.current)
              refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  const activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={refDiv}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{t("Menu")}</li>
            <LinkGenerator link={"/dashboard"} icon={"bx bx-home-circle"} text={t("Dashboard")} />
            <LinkGenerator link={"/organizations"} icon={"bx bx-sitemap"} text={t("Organizations")} />
            {/* <LinkGenerator link={"/admin-accounts"} icon={"bx bx-user-circle"} text={t("Super Accounts")} /> */}
            {
              adminData &&
              Object.keys(adminData)?.length > 0 &&
              <>
                <li className="menu-title">{t("Organization Selected")}</li>
                <LinkGenerator link={"/organizations/details"} icon={"bx bx-coin-stack"} text={adminData.tenantName} />
                <LinkGenerator link={"/channels"} icon={"bx bx-video"} text={t("Channels")} />
              </>
            }
            {
              adminData &&
              Object.keys(adminData).length > 0 &&
              adminData?.selectedChannel &&
              <>
                <li className="menu-title">{t("Channel Selected")}</li>
                <LinkGenerator link={`/channel/${adminData.selectedChannel.id}`} icon={"bx bx-coin-stack"} text={adminData.selectedChannel.name} />
                <LinkGenerator link={`/accounts/${adminData.selectedChannel.id}`} icon={"bx bx-user-pin"} text={t("Accounts")} />
              </>
            }
            {/* <li className="menu-title">Oganization Selected</li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file" />
                  <span>{t("BJJFlix")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="#">
                      {t("Details")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {t("Channels")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {t("Admins")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">{t("Users")}</Link>
                  </li>
                  <li>
                    <Link to="#">{t("Medias")}</Link>
                  </li>
                  <li>
                    <Link to="#">{t("Lives")}</Link>
                  </li>
                  <li>
                    <Link to="#">{t("Mobile APP")}</Link>
                  </li>
                </ul>
              </li> */}

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SidebarContent))

import React, { useState } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { createOrganization } from 'helpers/backend_helper'
import Loading from "components/Common/Loading"
import HeaderTitle from "components/Common/HeaderTitle"
import Breadcrumbs from "components/Common/Breadcrumb"

const OrganizationsCreate = () => {
  const history = useHistory()
  const [isError, setError] = useState([])
  const [isLoading, setLoading] = useState(false)

  const createNew = async (_form, values) => {
    setLoading(true)
    setError([])
    const data = { name: values["name"] }
    try {
      await createOrganization({ data })
      history.push('/organizations')
    } catch ({ response }) {
      setError(response.data.body.response.message)
      setLoading(false)
    }
  }

  return (
    <div className="page-content">
      <HeaderTitle text='New Organization' />
      <Container fluid>
        <Breadcrumbs title="Organizations" breadcrumbItem="Create New" />
        {
          isLoading && isError.length === 0 && <Loading />
        }
        {
          !isLoading &&
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Organization</CardTitle>
                  <AvForm
                    onValidSubmit={createNew}
                  >
                    <FormGroup className="mb-2" row>
                      <Label htmlFor="name" className="col-form-label col-lg-2">
                        Name
                      </Label>
                      <Col lg="10">
                        <AvField
                          name="name"
                          type="text"
                          errorMessage="Invalid name"
                          validate={{
                            required: { value: true }
                          }}
                          className="form-control"
                          placeholder="Enter Organization Name..."
                        />
                        {
                          !isLoading && isError.length > 0 &&
                          isError.map((err, i) => (
                            <div className="alert alert-danger mt-2" key={i}>{err}</div>
                          ))
                        }
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="info">
                          Create Organization
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}

export default OrganizationsCreate
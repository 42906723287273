import React from "react"
import { Redirect } from "react-router-dom"

import Login from "../pages/Authentication/Login"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import UserProfile from "../pages/Authentication/UserProfile"

import Dashboard from "../pages/Dashboard"
import Accounts from "../pages/Accounts"
import Organizations from "../pages/Organizations"
import OrganizationDetail from "../pages/Organizations/Details"
import OrganizationCreate from "../pages/Organizations/Create"
import OrganizationEdit from "../pages/Organizations/Edit"
import Channels from "../pages/Channels"
import ChannelDetail from "../pages/Channels/Details"
import ChannelCreate from "../pages/Channels/Create"
import ChannelEdit from "../pages/Channels/Edit"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/profile", component: UserProfile },
  { path: "/organizations", component: Organizations },
  { path: "/organizations/details", component: OrganizationDetail },
  { path: "/organizations/create", component: OrganizationCreate },
  { path: "/organizations/edit", component: OrganizationEdit },
  { path: "/channels", component: Channels },
  { path: "/channels/create", component: ChannelCreate },
  { path: "/channel/:id", component: ChannelDetail },
  { path: "/channels/:id/edit", component: ChannelEdit },
  { path: "/accounts/:id", component: Accounts },
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> }
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
]

export { authProtectedRoutes, publicRoutes }

import React, { useState } from "react"
import { Container, Row, Table } from "reactstrap"
import { useHistory } from "react-router-dom"

import Loading from "components/Common/Loading"
import CustomModal from 'components/Common/Modal'
import HeaderTitle from "components/Common/HeaderTitle"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useAdmin } from 'context/AdminContext'
import { deleteOrganization } from 'helpers/backend_helper'
import Card from "./Card"

const Dashboard = () => {
  const history = useHistory()
  const { adminData, setAdminData } = useAdmin()
  const [isError, setError] = useState()
  const [showModal, setShowModal] = useState({ show: false })

  const handleDelete = (id) => async () => {
    const preparedData = {
      data: { id }
    }
    try {
      await deleteOrganization({ ...preparedData })
      setAdminData({})
      history.push('/organizations')
    } catch (error) {
      //TODO: include visual alert
      setError(error.response?.data?.body?.message)
    }
  }

  const handleModal = (state, id) => () => {
    setShowModal({
      ...showModal,
      show: state,
      id
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <CustomModal
          title='Confirm'
          text='This operation is irreversible. Continue?'
          textConfirm='Confirm delete'
          status={showModal}
          actionCancel={handleModal}
          actionConfirm={handleDelete}
        />
        <HeaderTitle text='Details | FH SuperPortal' />
        {
          Object.keys(adminData).length === 0 &&
          <Loading />
        }
        {
          Object.keys(adminData).length > 0 &&
          <Container fluid>
            <Breadcrumbs title="Details" breadcrumbItem={adminData.tenantName} />
            <Card
              handleModal={handleModal}
              {...adminData}
            />
            <Row>
              <div className="mt-1">
                <h5 className="mb-3">Specifications:</h5>
                <div className="table-responsive">
                  <Table className="table mb-0 table-bordered">
                    <tbody>
                      {Object.keys(adminData)
                        .filter(x => typeof adminData[x] !== 'object')
                        .map((data, i) => (
                          <tr key={i}>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              {data}
                            </th>
                            <td>{adminData[data]}</td>
                          </tr>
                        )
                        )}
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="mt-5">
                <h5 className="mb-3">Specifications Details:</h5>
                <div className="table-responsive">
                  <Table className="table mb-0 table-bordered">
                    <tbody>
                      {Object.keys(adminData)
                        .filter(x => typeof adminData[x] === 'object')
                        .map((data, i) => (
                          <tr key={i}>
                            <th
                              scope="row"
                              style={{ width: "400px" }}
                              className={"text-capitalize"}
                            >
                              {data}
                            </th>
                            <td>
                              <pre>
                                {JSON.stringify(adminData[data], null, 2)}
                              </pre>
                            </td>
                          </tr>
                        )
                        )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Row>
          </Container>
        }
      </div>
    </React.Fragment>
  )
}

export default Dashboard

import React from "react"
import PropTypes from 'prop-types'
import { map } from "lodash"
import {
  Card,
  CardBody,
  Col,
  Button
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

const CustomCard = ({ t, channels, urlPath, change, handleModal }) => {
  return (
    <React.Fragment>
      {map(channels, (channel, dkey) => (
        <Col xl="4" sm="6" key={"__channelscd_" + dkey}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-lg me-4">
                  <span className="avatar-title bg-dark bg-gradient text-danger font-size-16">
                    {
                      channel.customization?.logo &&
                      <img src={`${urlPath}/${channel.customization?.thumbnail}`} alt="Logo" height="90" width={90} />
                    }
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15">
                    <div className="text-dark" >
                      {channel.name}
                    </div>
                  </h5>
                  <p className="text-muted mb-0"><b>Id:</b> {channel.id}</p>
                  <p className="text-muted mb-0"><b>Status:</b> {channel.status}</p>
                </div>
              </div>
            </CardBody>
            <div className='d-flex justify-content-around align-items-center mb-2'>
              <Button color="info" onClick={change({ ...channel })}>
                {t('Use this Channel')}
              </Button>
              <Button color="danger" onClick={handleModal(true, channel.id)}>
                {t('Delete')}
              </Button>
            </div>
          </Card>
        </Col>
      ))
      }
    </React.Fragment >
  )
}

CustomCard.propTypes = {
  t: PropTypes.any,
  urlPath: PropTypes.string,
  channels: PropTypes.array,
  change: PropTypes.func,
  handleModal: PropTypes.func,
}

export default withRouter(withTranslation()(CustomCard))

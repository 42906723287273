import authHeader from './auth-token-header'
import axios from "axios"

const ORG_DEFAULT_LOGIN = 'https://threedotzero.fanhero.tv'

const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

const includeOrganization = (config, token = null, org = null, channel = null) => ({
  ...config,
  headers: {
    common: {
      organization: org || ORG_DEFAULT_LOGIN,
      authorization: token,
      channel
    }
  }
})

export async function get({ url, configs = {}, org = null, channel = null }) {
  const token = authHeader()
  const config = includeOrganization(configs, token, org, channel)
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
}

export async function post({ url, data, configs = {}, org = null }) {
  const token = authHeader()
  const config = includeOrganization(configs, token, org)
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del({ url, data, configs = {}, org = null }) {
  const token = authHeader()
  const config = includeOrganization(configs, token, org)
  return await axiosApi
    .delete(`${url}/${data.id}`, { ...config })
    .then(response => response.data)
}

export async function patch({ url, data, configs = {}, org = null }) {
  const token = authHeader()
  const config = includeOrganization(configs, token, org)
  return await axiosApi
    .patch(`${url}/${data.id}`, { ...data }, { ...config })
    .then(response => response.data)
}
import React, { Component } from "react"
import { Container } from "reactstrap"
import HeaderTitle from "components/Common/HeaderTitle";

class Dashboard extends Component {
  render() {
    return (
      <div className="page-content">
        <HeaderTitle text='Dashboard' />
        <Container fluid>
          <h4>Dashboard</h4>
        </Container>
      </div>
    )
  }
}

export default Dashboard;

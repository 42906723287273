import React from 'react'
import PropTypes from 'prop-types'
import { Container, Card, CardBody, CardTitle, CardText } from "reactstrap"

const EmptyCard = ({ title, text }) => (
  <Container className="mt-2">
    <Card color="info" className="text-white-50">
      <CardBody>
        <CardTitle className="h5 mt-0 mb-4 text-white">
          <i className="mdi mdi-alert-outline me-3" />
          {title}
        </CardTitle>
        <CardText>
          {text}
        </CardText>
      </CardBody>
    </Card>
  </Container>
)

EmptyCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}

export default EmptyCard
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Container, Row } from "reactstrap"
import { withRouter, Link, useHistory } from "react-router-dom"
import { useAdmin } from 'context/AdminContext'
import { getListAllChannels, deleteChannel } from 'helpers/backend_helper'

import Loading from "components/Common/Loading"
import CustomModal from 'components/Common/Modal'
import EmptyCard from "components/Common/EmptyCard"
import HeaderTitle from "components/Common/HeaderTitle"
import Breadcrumbs from "components/Common/Breadcrumb"
import CustomCard from "./Card"

const Channels = () => {
  const history = useHistory()
  const { adminData, setAdminData } = useAdmin()
  const [channels, setChannels] = useState()
  const [showModal, setShowModal] = useState({ show: false })

  const getAllData = async () => {
    if (Object.keys(adminData).length > 0) {
      const { body } = await getListAllChannels({ org: adminData.organization })
      setChannels(body.data)
    }
  }

  useEffect(async () => getAllData(), [adminData])

  const changeChannel = (selectedChannel) => () => {
    setAdminData({
      ...adminData,
      selectedChannel
    })
    history.push(`/channel/${selectedChannel.id}`)
  }

  const handleDelete = (id) => async () => {
    const preparedData = {
      data: { id },
      org: adminData.organization
    }
    try {
      await deleteChannel({ ...preparedData })
      setShowModal({ show: false })
      getAllData()
    } catch ({ response }) {
      console.log(response)
    }
  }

  const handleModal = (state, id) => () => {
    setShowModal({
      ...showModal,
      show: state,
      id
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <HeaderTitle text='Channels' />
        <CustomModal
          title='Confirm'
          text='This operation is irreversible. Continue?'
          textConfirm='Confirm delete'
          status={showModal}
          actionCancel={handleModal}
          actionConfirm={handleDelete}
        />
        <Container fluid>
          <Breadcrumbs title="Channels" breadcrumbItem="List of Channels" />
          <Row className="mb-2">
            <div className="text-sm-end">
              <Link
                to={'/channels/create'}
                className="font-16 btn-block btn btn-info"
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New
              </Link>
            </div>
          </Row>
          <Row>
            {!channels && <Loading />}
            {
              channels &&
              channels.length === 0 &&
              <EmptyCard title='No channels found' text='Create a new channel' />
            }
            {
              channels &&
              channels.length > 0 &&
              <CustomCard
                urlPath={adminData?.imageCdnBaseUrl}
                channels={channels}
                change={changeChannel}
                handleModal={handleModal}
              />
            }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default connect()(withRouter(Channels))

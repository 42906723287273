import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Container, Row } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { withRouter, Link } from "react-router-dom"
import { useAdmin } from 'context/AdminContext'

import Loading from "components/Common/Loading"
import HeaderTitle from "components/Common/HeaderTitle"
import Breadcrumbs from "components/Common/Breadcrumb"

import { getListAllAccounts } from 'helpers/backend_helper'

const Accounts = () => {
  const { adminData } = useAdmin()
  const [accounts, setAccounts] = useState()

  useEffect(async () => {
    if (Object.keys(adminData).length > 0 && Object.keys(adminData.selectedChannel).length > 0) {
      const { rows } = await getListAllAccounts({
        org: typeof adminData.webUrl === 'string' ? adminData.webUrl : adminData.webUrl[0],
        channel: adminData.selectedChannel.id
      })
      setAccounts(rows)
    }
  }, [adminData])

  return (
    <React.Fragment>
      <div className="page-content">
        <HeaderTitle text='Accounts' />
        <Container fluid>
          <Breadcrumbs title="Accounts" breadcrumbItem="List" />
          {!accounts && <Loading />}
          {
            accounts &&
            <Row>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th data-priority="1">Ids</Th>
                        <Th data-priority="3">Person</Th>
                        <Th data-priority="1">Username</Th>
                        <Th data-priority="6">Email</Th>
                        <Th data-priority="6">Status</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {
                        accounts.map(acc => (
                          <Tr key={acc.id}>
                            <Td>
                              User Id: {acc.id}<br />
                              Tenant Id: {acc.tenant_id}<br />
                            </Td>
                            <Td>
                              {acc.display_name}<br />
                              {acc.first_name}<br />
                              {acc.last_name}
                            </Td>
                            <Td>{acc.username}</Td>
                            <Td>{acc.email}</Td>
                            <Td>
                              Active: {String(acc.status.active)}<br />
                              Block Temp: {String(acc.status.block_temp)}<br />
                              Block Perm: {String(acc.status.block_perm)}<br />
                              Pending Activation: {String(acc.status.pending_activation)}
                            </Td>
                          </Tr>
                        ))
                      }
                    </Tbody>
                  </Table>
                  <Container>
                    <nav >
                      <ul className="pagination justify-content-center">
                        <li className="page-item disabled">
                          <span className="page-link"><i className="mdi mdi-chevron-left"></i></span>
                        </li>
                        <li className="page-item active">
                          <span className="page-link">
                            1
                          </span>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" to="#">
                            2
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" to="#">
                            3
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" to="#">
                            <i className="mdi mdi-chevron-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </Container>
                </div>
              </div>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}


export default connect()(withRouter(Accounts))

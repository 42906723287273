import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

import { apiError, loginUser } from "../../store/actions"
import profile from "../../assets/images/FH_Logo_white.png"

const Login = ({ apiError, error, loginUser, history }) => {

  useEffect(() => {
    apiError("")
  }, [])

  const handleValidSubmit = (event, values) => {
    loginUser(values, history)
  }

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-black">
                <Row>
                  <div className="p-4">
                    <img src={profile} alt="" className="img-fluid" />
                  </div>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div className="p-2 mt-4">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={handleValidSubmit}
                  >
                    {error && <Alert color="danger">{error}</Alert>}
                    <div className="mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="password"
                        label="Password"
                        type="password"
                        required
                        placeholder="Enter Password"
                      />
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1" /> Forgot your
                        password?
                      </Link>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              <p>
                © {new Date().getFullYear()} FanHero. Crafted with
                <i className="mdi mdi-heart text-danger" /> by Brazilians
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

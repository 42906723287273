import React, { createContext, useState, useContext, useEffect } from 'react'
import PropTypes from "prop-types"

export const AdminContext = createContext()

export const AdminProvider = ({ children }) => {
  const [adminData, setAdminData] = useState({})

  useEffect(() => {
    const hasSelection = localStorage.getItem('superPortalOrg')
    if (hasSelection) {
      setAdminData(JSON.parse(hasSelection))
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('superPortalOrg', JSON.stringify(adminData))
  }, [adminData])

  return (
    <AdminContext.Provider value={{ adminData, setAdminData }}>
      {children}
    </AdminContext.Provider>
  )
}

AdminProvider.propTypes = {
  children: PropTypes.any
}

export const useAdmin = () => {
  const context = useContext(AdminContext)
  if (!context) {
    throw new Error('useAdmin must be used within an AdminProvider')
  }
  return context
}

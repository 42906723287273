import React from "react"
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
import { map } from "lodash"
import {
  Card,
  CardBody,
  Col,
  Button
} from "reactstrap"
import { withTranslation } from "react-i18next"

const CustomCard = ({ orgs, t, change, handleModal }) => {
  return (
    <React.Fragment>
      {map(orgs, (organization, dkey) => (
        <Col xl="4" sm="6" key={"__orgcod_" + dkey}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-lg me-4">
                  <span className="avatar-title bg-dark bg-gradient text-danger font-size-16">
                    {
                      organization.customization?.icon &&
                      <img src={organization.imageCdnBaseUrl + '/' + organization.customization.icon} alt="" height="90" width={90} />
                    }
                  </span>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15">
                    <div className="text-dark" >
                      {organization.name}
                    </div>
                  </h5>
                  <p className="text-muted mb-0"><b>WebURL:</b> {organization.webUrl}</p>
                </div>
              </div>
            </CardBody>
            <div className='d-flex justify-content-around align-items-center mb-2'>
              {
                organization.webUrl &&
                <>
                  <Button color="info" onClick={change({ ...organization })}>
                    {t('Use this Org')}
                  </Button>
                  <Button color="danger" onClick={handleModal(true, organization.id)}>
                    {t('Delete')}
                  </Button>
                </>
              }
            </div>
          </Card>
        </Col>
      ))
      }
    </React.Fragment >
  )
}

CustomCard.propTypes = {
  orgs: PropTypes.array,
  t: PropTypes.any,
  change: PropTypes.func,
  handleModal: PropTypes.func,
}

export default withRouter(withTranslation()(CustomCard))

import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import { useAdmin } from 'context/AdminContext'
import { updateChannel } from 'helpers/backend_helper'
import Loading from "components/Common/Loading"
import HeaderTitle from "components/Common/HeaderTitle"
import Breadcrumbs from "components/Common/Breadcrumb"

const SubmitButton = ({ id }) => (
  <Row className="mb-2">
    <Col className="d-flex justify-content-between">
      <Button type="submit" color="info">
        Update Channel
      </Button>
      <Link
        to={`/channel/${id}`}
        className="font-16 btn-block btn btn-secondary"
      >
        Cancel Edit
      </Link>
    </Col>
  </Row>
)

SubmitButton.propTypes = {
  id: PropTypes.string
}

const ChannelsEdit = () => {
  const history = useHistory()
  const { adminData, setAdminData } = useAdmin()
  const [isLoading, setLoading] = useState(false)
  const [editData, setEditData] = useState()

  useEffect(() => {
    if (adminData) {
      const prepareEditData = {
        ...adminData.selectedChannel
      }
      delete prepareEditData.id
      setEditData(prepareEditData)
    }
  }, [adminData])

  const updateData = async (_form, values) => {
    setLoading(true)
    try {
      const prepareData = JSON.parse(values.newData)
      const data = { ...prepareData, id: adminData.selectedChannel.id }
      await updateChannel({ data })
      setAdminData({
        ...adminData,
        selectedChannel: null
      })
      history.push('/channels')
    } catch (error) {
      setLoading(false)
      console.log(error)
      alert('Invalid JSON or error to save')
    }
  }

  return (
    <div className="page-content">
      <HeaderTitle text='Edit Channel' />
      <Container fluid>
        <Breadcrumbs title="Channel" breadcrumbItem="Edit" />
        {isLoading && <Loading />}
        {
          !isLoading && editData &&
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Edit Channel: {adminData.selectedChannel?.name}</CardTitle>
                  <AvForm onValidSubmit={updateData}>
                    <SubmitButton id={adminData.selectedChannel.id} />
                    <AvField
                      name="newData"
                      type="textarea"
                      className="form-control"
                      rows={40}
                      value={JSON.stringify(editData, null, 2)}
                    />
                    <SubmitButton id={adminData.selectedChannel.id} />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}

export default ChannelsEdit
import { takeEvery, put, call } from "redux-saga/effects"

import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, apiError } from "./actions"

import { postJwtLogin } from "../../../helpers/backend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, { ...user })
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error.response?.data?.body?.message || error.message))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga

import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Container, Row, Alert } from "reactstrap"
import { withRouter, Link, useHistory } from "react-router-dom"
import { useAdmin } from 'context/AdminContext'
import { getListAllOrganizations, deleteOrganization } from 'helpers/backend_helper'

import Loading from "components/Common/Loading"
import CustomModal from 'components/Common/Modal'
import HeaderTitle from "components/Common/HeaderTitle"
import Breadcrumbs from "components/Common/Breadcrumb"
import CustomCard from "./Card"

const Organizations = () => {
  const history = useHistory()
  const { adminData, setAdminData } = useAdmin()
  const [isError, setError] = useState()
  const [organizations, setOrganizations] = useState()
  const [showModal, setShowModal] = useState({ show: false })

  const getAllData = async () => {
    try {
      const result = await getListAllOrganizations()
      if (result) {
        setOrganizations(result.body.data)
      }
    } catch (error) {
      setError(error.response?.data?.body?.message)
    }
  }

  useEffect(async () => getAllData(), [])

  const changeOrg = (org) => () => {
    const organization = typeof org.webUrl === 'string' ? org.webUrl : org.webUrl[0]
    setAdminData({
      ...adminData,
      ...org,
      tenantName: org.name,
      organization,
      selectedChannel: null
    })
    history.push('/organizations/details')
  }

  const handleDelete = (id) => async () => {
    const preparedData = {
      data: { id }
    }
    try {
      await deleteOrganization({ ...preparedData })
      setShowModal({ show: false })
      getAllData()
    } catch ({ response }) {
      console.log(response)
    }
  }

  const handleModal = (state, id) => () => {
    setShowModal({
      ...showModal,
      show: state,
      id
    })
  }

  return (
    <div className="page-content">
      <HeaderTitle text='Organizations' />
      <CustomModal
        title='Confirm'
        text='This operation is irreversible. Continue?'
        textConfirm='Confirm delete'
        status={showModal}
        actionCancel={handleModal}
        actionConfirm={handleDelete}
      />
      <Container fluid>
        <Breadcrumbs title="Organizations" breadcrumbItem="List" />
        <Row className="mb-2">
          <div className="text-sm-end">
            <Link
              to={'/organizations/create'}
              className="font-16 btn-block btn btn-info"
            >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Create New
            </Link>
          </div>
        </Row>
        <Row>
          {isError && <Alert color="danger">{isError}</Alert>}
          {!organizations && !isError && <Loading />}
          {
            organizations && !isError &&
            <CustomCard
              orgs={organizations}
              change={changeOrg}
              handleModal={handleModal}
            />
          }
        </Row>
      </Container>
    </div>
  )
}

export default connect()(withRouter(Organizations))

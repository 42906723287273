import React, { useState } from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { useAdmin } from 'context/AdminContext'
import { createChannel } from 'helpers/backend_helper'
import Loading from "components/Common/Loading"
import HeaderTitle from "components/Common/HeaderTitle"
import Breadcrumbs from "components/Common/Breadcrumb"

const ChannelsCreate = () => {
  const history = useHistory()
  const { adminData } = useAdmin()
  const [isError, setError] = useState()
  const [isLoading, setLoading] = useState(false)

  const createNew = async (_form, values) => {
    setLoading(true)
    setError()
    const newData = {
      data: {
        name: values["name"],
        description: values["description"]
      },
      org: adminData.organization
    }
    try {
      await createChannel({ ...newData })
      history.push('/channels')
    } catch ({ response }) {
      setError(response?.data?.body?.message)
      setLoading(false)
    }
  }

  return (
    <div className="page-content">
      <HeaderTitle text='New Channel' />
      <Container fluid>
        <Breadcrumbs title="Channels" breadcrumbItem="Create New" />
        {
          isLoading && <Loading />
        }
        {
          !isLoading &&
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Channel</CardTitle>
                  <AvForm
                    onValidSubmit={createNew}
                  >
                    <FormGroup className="mb-2" row>
                      <Label htmlFor="name" className="col-form-label col-lg-2">
                        Name
                      </Label>
                      <Col lg="10">
                        <AvField
                          name="name"
                          type="text"
                          errorMessage="Invalid name"
                          validate={{
                            required: { value: true }
                          }}
                          className="form-control"
                          placeholder="Enter Organization Name..."
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-2" row>
                      <Label htmlFor="name" className="col-form-label col-lg-2">
                        Description
                      </Label>
                      <Col lg="10">
                        <AvField
                          name="description"
                          type="text"
                          errorMessage="Invalid description"
                          validate={{
                            required: { value: true }
                          }}
                          className="form-control"
                          placeholder="Enter Channel description..."
                        />
                      </Col>
                    </FormGroup>
                    {
                      !isLoading && isError &&
                      <div className="alert alert-danger mt-2">{isError}</div>
                    }
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="info">
                          Create Channel
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </div>
  )
}

export default ChannelsCreate
import React, { useState } from "react"
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

const DetailsCard = ({ t, id, imageCdnBaseUrl, customization, name, kind, status, description, handleModal }) => {
  const [settingsMenu, setSettingsMenu] = useState(false)

  const toggleSettings = () => setSettingsMenu(!settingsMenu)

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            <Row>
              <Col lg="6">
                <div className="d-flex">
                  <div className="me-3">
                    <img
                      src={`${imageCdnBaseUrl}/${customization?.thumbnail}`}
                      alt="Logo"
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <div className="flex-grow-1 align-self-center">
                    <div className="text-muted">
                      <p className="mb-1">Welcome to</p>
                      <h5 className="mb-1">{name}</h5>
                      <p className="mb-0">Id: {id}</p>
                      <p className="mb-0">Description: {description}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="6" className="align-self-center">
                <div className="text-lg-center mt-4 mt-lg-0">
                  <Row>
                    <Col xs="6">
                      <div>
                        <p className="text-muted text-truncate mb-2">
                          Kind
                        </p>
                        <h5 className="mb-0">{kind}</h5>
                      </div>
                    </Col>
                    <Col xs="3">
                      <div>
                        <p className="text-muted text-truncate mb-2">
                          Status
                        </p>
                        <h5 className="mb-0">{status}</h5>
                      </div>
                    </Col>
                    <Col lg="3" className="d-none d-lg-block">
                      <div className="clearfix mt-4 mt-lg-0">
                        <Dropdown
                          isOpen={settingsMenu}
                          toggle={toggleSettings}
                          className="float-end"
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-info"
                          >
                            <i className="bx bxs-cog align-middle me-1"></i>{" "}
                            {t('Options')}
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href={`/channels/${id}/edit`}>
                              {t('Edit This Channel')}
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={handleModal(true, id)}>
                              {t('Delete this Channel')}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

DetailsCard.propTypes = {
  t: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  kind: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string,
  imageCdnBaseUrl: PropTypes.string,
  customization: PropTypes.shape({
    thumbnail: PropTypes.string
  }),
  handleModal: PropTypes.func
}

export default withRouter(withTranslation()(DetailsCard))

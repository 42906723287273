import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from "reactstrap"

const CustomModal = ({ title, text, textConfirm, status, actionCancel, actionConfirm }) => (
  <Modal
    isOpen={status.show}
    toggle={actionCancel(!status.show)}
    scrollable={true}
    backdrop={'static'}
    id="staticBackdrop"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="staticBackdropLabel">
        {title}
      </h5>
      <button type="button" className="btn-close" onClick={actionCancel(false)} aria-label="Close" />
    </div>
    <div className="modal-body">
      <p>{text}</p>
      <p>Id: {status.id}</p>
    </div>
    <div className="modal-footer">
      <button type="button" className="btn btn-light" onClick={actionCancel(false)}>
        Cancel
      </button>
      <button
        type="button"
        className="btn btn-danger"
        onClick={actionConfirm(status.id)}
      >
        {textConfirm}
      </button>
    </div>
  </Modal>
)

CustomModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  textConfirm: PropTypes.string,
  status: PropTypes.shape({
    show: PropTypes.bool,
    id: PropTypes.string
  }),
  actionCancel: PropTypes.func,
  actionConfirm: PropTypes.func
}

export default CustomModal
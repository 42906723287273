import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { connect } from "react-redux";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const { account } = JSON.parse(localStorage.getItem("authUser"))
    return account;
  }
}

const ProfileMenu = ({ t, history }) => {
  const [state, setState] = useState({ menu: false, name: "No Name" })

  const logout = () => {
    localStorage.removeItem("authUser")
    localStorage.removeItem("superPortalOrg")
    history.push("/login")
  }

  const toggle = () => {
    setState(prevState => ({
      ...state,
      menu: !prevState.menu,
    }))
  }

  useEffect(() => {
    const userData = getUserName();
    if (userData) {
      setState({ name: userData.first_name })
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={state.menu}
        toggle={toggle}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1">
            {state.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {t("Profile")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <DropdownItem tag="a" onClick={logout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
  history: PropTypes.object
}

const mapStateToProps = state => {
  const { success } = state.Profile
  return { success }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)

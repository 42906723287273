export default function authHeader() {
  try {
    const { token } = JSON.parse(localStorage.getItem("authUser"))
    return (token)
      ? 'Bearer ' + token.accessToken
      : null
  } catch (error) {
    return
  }
}
